import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { COLOR_CONSTANTS, colors, fontSizes } from 'theme'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Input from 'components/atoms/Input'
import Switch from 'components/atoms/Switch'
import TextArea from 'components/atoms/TextArea'
import DropDown from 'shared/DropDown'
import {
  BUTTON_THEME,
  DEFAULT_THEME_CONTENT_WIDTH,
  DEFAULT_THEME_CORNER_RADIUS,
  DEFAULT_THEME_SCALE,
  DEFAULT_THEME_SHADOW,
  DEFAULT_THEME_TRANSPARENCY,
  VISTA_PAGE_THEME_TEMPLATE_DEFAULT,
  SCALE_CONSTANT,
  DEFAULT_THEME_TEXT_FONT_WEIGHT,
  DEFAULT_THEME_TEXT_FONT_STYLE,
  LINK_TYPE_EXTERNAL,
  LINK_TYPE_INTERNAL,
  LINK_TYPES,
  PAGE_BLOCK_ID_START,
} from 'routes/VistaPage/consts'
// eslint-disable-next-line import/no-cycle
import {
  collectItemsForInternalLinks,
  getBackgroundStyleString,
  getTextFontStyleString,
  getTextFontWeightString,
} from 'routes/VistaPage/helper'
import ThemeComponent from 'routes/VistaPage/VistaPageDetail/components/ThemeComponent'
import FormatSelectInternalBlockOption from 'routes/VistaPage/VistaPageDetail/components/components/FormatSelectInternalBlockOption'

const StyledApplyDefaultThemeText = styled(Text)`
  color: ${colors.primary};
  font-size: ${fontSizes.xs};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const StyledButtonWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: ${({ $fontSize }) => $fontSize}em;
  box-shadow: rgb(145 147 169 / 85%) 0px 0px ${({ $boxShadow }) => $boxShadow}em;
  overflow: hidden;
  cursor: pointer;
  position: relative;
`

const StyledButtonBackground = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  ${({ $background }) => $background};
  opacity: ${({ $transparency }) => ($transparency ? 1 - $transparency : 1)};
`

const StyledText = styled(Text)`
  box-sizing: border-box;
  white-space: break-spaces;
  z-index: 1;
  ${({ $color }) => $color};
  ${({ $fontStyle }) => $fontStyle};
  ${({ $fontWeight }) => $fontWeight};
`

const { LABEL, LINK_TYPE, LINK_EXTERNAL, LINK_INTERNAL, DETAILED_APPEARANCE_THEME } = {
  LABEL: 'label',
  LINK_TYPE: 'link_type',
  LINK_EXTERNAL: 'link',
  LINK_INTERNAL: 'link_internal',
  DETAILED_APPEARANCE_THEME: 'detailed_appearance_theme',
}

const FormValidationSchema = () => {
  return Yup.object().shape({
    [LABEL]: Yup.string().max(200, `Label is too long - should be 200 chars maximum.`),
  })
}

const ButtonLinkComponent = ({
  data,
  vistaPage,
  isEditable,
  handleChangeBlockComponent,
  handleCollectTrackingData,
}) => {
  const [pageItems, setPageItems] = useState([])

  const {
    page_id,
    _id: block_id,
    data: {
      label = '',
      link: link_external = '',
      link_type = LINK_TYPE_EXTERNAL,
      link_internal = null,
      detailed_appearance_theme = false,
    } = {},
  } = data

  useEffect(() => {
    if (isEditable) {
      const { items_transformed } = collectItemsForInternalLinks({ vistaPage, page_id, block_id })
      setPageItems([...items_transformed])
    }
  }, [])

  const { appearance = VISTA_PAGE_THEME_TEMPLATE_DEFAULT } = vistaPage

  const appearance_theme = appearance[BUTTON_THEME] || {}

  const theme = data.data ? data.data[BUTTON_THEME] || appearance_theme : appearance_theme

  const has_theme_changed = !!(data.data && data.data[BUTTON_THEME])

  const {
    background = appearance_theme.background,
    text_scale = appearance_theme.scale || DEFAULT_THEME_SCALE,
    text_color = appearance_theme.text_color,
    text_font_weight = appearance_theme.text_font_weight || DEFAULT_THEME_TEXT_FONT_WEIGHT,
    text_font_style = appearance_theme.text_font_style || DEFAULT_THEME_TEXT_FONT_STYLE,
    content_width = appearance_theme.content_width || DEFAULT_THEME_CONTENT_WIDTH,
    corner_radius = appearance_theme.corner_radius || DEFAULT_THEME_CORNER_RADIUS,
    shadow = appearance_theme.shadow || DEFAULT_THEME_SHADOW,
    transparency = appearance_theme.transparency || DEFAULT_THEME_TRANSPARENCY,
  } = theme || {}

  const { background_color } = background || {}
  const button_background = getBackgroundStyleString({ background_color })

  const { color } = text_color || {}
  const text_font_color = getBackgroundStyleString({ background_color: color, type: 'text' })
  const text_font_style_string = getTextFontStyleString({ style: text_font_style })
  const text_font_weight_string = getTextFontWeightString({ weight: text_font_weight })

  return (
    <Fragment>
      {isEditable ? (
        <Flex flexDirection="column" width="100%" height="100%">
          <Formik
            initialValues={{
              [LABEL]: label,
              [LINK_TYPE]: link_type || LINK_TYPES[0].value,
              [LINK_EXTERNAL]: link_external,
              [LINK_INTERNAL]: link_internal,
            }}
            validationSchema={FormValidationSchema}
            onSubmit={() => {}}
            autocomplete="off"
            validateOnBlur
          >
            {({ values, errors, setFieldValue }) => (
              <Box width="100%">
                <Box>
                  <TextArea
                    rows={3}
                    placeholder="Button label"
                    defaultValue={label}
                    onChange={(event) => {
                      handleChangeBlockComponent({ page_id, block_id, key: LABEL, value: event.target.value })
                      setFieldValue(LABEL, event.target.value)
                    }}
                    error={values[LABEL] && errors[LABEL]}
                    width="100%"
                  />
                </Box>

                <Box mt="m">
                  <DropDown
                    placeholder="Select link type"
                    value={values[LINK_TYPE] && LINK_TYPES.find(({ value }) => value === values[LINK_TYPE])}
                    onChange={(option) => {
                      if (option) {
                        handleChangeBlockComponent({ page_id, block_id, key: LINK_TYPE, value: option.value })
                        setFieldValue(LINK_TYPE, option.value)
                      }
                    }}
                    options={LINK_TYPES}
                    openMenuOnFocus
                    isSearchable={false}
                  />
                </Box>

                {values[LINK_TYPE] === LINK_TYPE_EXTERNAL && (
                  <Box mt="m">
                    <Input
                      placeholder="Button link"
                      type="text"
                      defaultValue={link_external}
                      onChange={(event) => {
                        handleChangeBlockComponent({ page_id, block_id, key: LINK_EXTERNAL, value: event.target.value })
                        setFieldValue(LINK_EXTERNAL, event.target.value)
                      }}
                      error={values[LINK_EXTERNAL] && errors[LINK_EXTERNAL]}
                      width="100%"
                    />
                  </Box>
                )}

                {values[LINK_TYPE] === LINK_TYPE_INTERNAL && (
                  <Box mt="m">
                    <DropDown
                      placeholder="Select page block"
                      value={
                        values[LINK_INTERNAL] && pageItems.length
                          ? pageItems.find(({ value }) => value === values[LINK_INTERNAL])
                          : null
                      }
                      onChange={(option) => {
                        if (option) {
                          handleChangeBlockComponent({ page_id, block_id, key: LINK_INTERNAL, value: option.value })
                          setFieldValue(LINK_INTERNAL, option.value)
                        }
                      }}
                      options={pageItems}
                      openMenuOnFocus
                      isSearchable={false}
                      formatOptionLabel={(data) => FormatSelectInternalBlockOption(data)}
                      error={values[LINK_INTERNAL] && errors[LINK_INTERNAL]}
                    />
                  </Box>
                )}
              </Box>
            )}
          </Formik>

          <Flex mt="l" alignItems="center" justifyContent="space-between">
            <Switch
              isOn={detailed_appearance_theme}
              onClick={() => {
                handleChangeBlockComponent({
                  page_id,
                  block_id,
                  key: DETAILED_APPEARANCE_THEME,
                  value: !detailed_appearance_theme,
                })
              }}
              text="Edit theme"
              color={COLOR_CONSTANTS.DENIM}
              fontWeight="normal"
            />

            {detailed_appearance_theme && has_theme_changed && (
              <StyledApplyDefaultThemeText
                onClick={() => {
                  handleChangeBlockComponent({ page_id, block_id, key: BUTTON_THEME, value: null })
                }}
              >
                Apply default page theme
              </StyledApplyDefaultThemeText>
            )}
          </Flex>

          {detailed_appearance_theme && (
            <Flex flexDirection="column" mt="m">
              <ThemeComponent
                handleChangeParameter={({ key, value }) => {
                  handleChangeBlockComponent({ page_id, block_id, type: BUTTON_THEME, key, value })
                }}
                data={theme}
                showBackgroundColorSettingsPaletteTypeMedia={false}
                showHeaderMediasScaleSettings={false}
                showHeaderFormatSettings={false}
                showSocialLinksImagesScaleSettings={false}
                showSocialLinksColoredSettings={false}
                showImageLinkImageScaleSettings={false}
              />
            </Flex>
          )}
        </Flex>
      ) : (
        <Flex alignItems="center" justifyContent="center">
          <StyledButtonWrapper
            padding={`${20 * SCALE_CONSTANT}px`}
            width={`${content_width}%`}
            borderRadius={`${corner_radius}em`}
            $boxShadow={shadow}
            $fontSize={text_scale}
            as={link_type === LINK_TYPE_EXTERNAL && link_external ? 'a' : 'div'}
            href={link_type === LINK_TYPE_EXTERNAL && link_external ? link_external : ''}
            target={link_type === LINK_TYPE_EXTERNAL && link_external ? '_blank' : ''}
            onClick={() => {
              if (link_type === LINK_TYPE_INTERNAL && link_internal) {
                const el = document.getElementById(`${PAGE_BLOCK_ID_START}${link_internal}`)
                if (el) {
                  el.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }
              }
              handleCollectTrackingData()
            }}
            alt={label}
          >
            <StyledButtonBackground $background={button_background} $transparency={transparency} />
            <StyledText
              fontSize={`${0.95}em`}
              textAlign="center"
              $color={text_font_color}
              $fontWeight={text_font_weight_string}
              $fontStyle={text_font_style_string}
            >
              {label}
            </StyledText>
          </StyledButtonWrapper>
        </Flex>
      )}
    </Fragment>
  )
}

ButtonLinkComponent.defaultProps = {
  isPublic: false,
  isEditable: false,
  handleChangeBlockComponent: () => {},
  handleCollectTrackingData: () => {},
}

ButtonLinkComponent.propTypes = {
  data: PropTypes.object.isRequired,
  vistaPage: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  handleChangeBlockComponent: PropTypes.func,
  handleCollectTrackingData: PropTypes.func,
  isPublic: PropTypes.bool,
}

ButtonLinkComponent.displayName = 'ButtonLinkComponent'

export default ButtonLinkComponent
