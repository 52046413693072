import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Field, FieldArray, Formik } from 'formik'
import * as Yup from 'yup'
import ClipLoader from 'react-spinners/ClipLoader'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { COLOR_CONSTANTS, colors, radius, space, fontSizes } from 'theme'
import { IMAGE } from 'consts'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Input from 'components/atoms/Input'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Image from 'components/atoms/Image'
import Button from 'components/atoms/Button'
import Icon from 'components/atoms/Icon'
import MediaUploadComponent from '../MediaUploadComponent'

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -9px;
  right: -9px;
  background: ${COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  z-index: 2;
`

const StyledItemWrapper = styled(Flex)`
  box-shadow: 0px 0px 8px 2px rgba(39, 40, 49, 0.03);
  padding: ${space.m};
  flex-direction: column;
  width: 100%;
  margin-top: ${space.m};
  border-radius: ${radius.l};
`

const NavigationItemIconWrapper = styled(Box)`
  background: ${COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: rgb(145 158 171 / 30%) 0px 0px 4px;
  z-index: 2;
`

const MediaWrapper = styled(Flex)`
  cursor: pointer;
  border-radius: ${radius.l};
  position: relative;
  flex-direction: column;
  text-align: center;
  ${({ $border }) => $border && `border: 1px solid ${COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL};`}
`

const StyledFlex = styled(Flex)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const StyledImageWithFallback = styled(ImageWithFallback)`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: ${radius.l};
  aspect-ratio: 1;
`

const StyledUploadProgressIndicatorText = styled(Text)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: ${fontSizes.xs};
  color: ${colors.primary};
`

const { ITEMS, MEDIAS, ALT_TEXT, LINK } = {
  ITEMS: 'items',
  MEDIAS: 'medias',
  ALT_TEXT: 'alt_text',
  LINK: 'link',
}

const FormValidationSchema = () => {
  return Yup.object().shape({
    [ITEMS]: Yup.array().of(
      Yup.object().shape({
        [MEDIAS]: Yup.array(),
        [ALT_TEXT]: Yup.string().max(300, `Alt text is too long - should be 300 chars maximum.`),
      })
    ),
  })
}

const ImageLinksGridComponent = ({
  user,
  data,
  vistaPage,
  isEditable,
  handleChangeBlockComponent,
  handleCollectTrackingData,
}) => {
  const { page_id, _id: block_id, data: { items = [] } = {} } = data

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result, setFieldValue) => {
    if (!result.destination) {
      return
    }

    const reordered_items = reorder(items, result.source.index, result.destination.index)

    setFieldValue(ITEMS, reordered_items)

    handleChangeBlockComponent({
      page_id,
      block_id,
      key: ITEMS,
      value: reordered_items,
    })
  }

  return (
    <Fragment>
      {isEditable ? (
        <Flex flexDirection="column" width="100%" height="100%">
          <Formik
            initialValues={{
              [ITEMS]: items,
            }}
            validationSchema={FormValidationSchema}
            onSubmit={() => {}}
            autocomplete="off"
            validateOnBlur
          >
            {({ values, setFieldValue }) => (
              <Box width="100%">
                <FieldArray
                  name="items"
                  render={(arrayHelpers) => (
                    <form autoComplete="off">
                      <Flex width="100%" justifyContent="center">
                        <Button.Gray
                          type="button"
                          isSmall
                          onClick={() => {
                            const item = {
                              _id: `${new Date().getTime()}`,
                              data: { [LINK]: '', [ALT_TEXT]: '', [MEDIAS]: [] },
                            }

                            arrayHelpers.push(item)

                            handleChangeBlockComponent({ page_id, block_id, key: ITEMS, value: [...items, item] })
                          }}
                        >
                          Add new image
                        </Button.Gray>
                      </Flex>

                      {values[ITEMS].length === 0 && (
                        <Text mt="m" textAlign="center">
                          Add your images by clicking above
                        </Text>
                      )}

                      <DragDropContext
                        onDragEnd={(result) => {
                          onDragEnd(result, setFieldValue)
                        }}
                      >
                        <Droppable droppableId="droppable" direction="vertical">
                          {(provided) => (
                            <Flex flexDirection="column" {...provided.droppableProps} ref={provided.innerRef}>
                              {values[ITEMS].map((item, index) => (
                                <Draggable key={index} draggableId={`draggable-${index}`} index={index}>
                                  {(provided) => (
                                    <Flex
                                      alignItems="center"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <Field name={`${ITEMS}.${index}`}>
                                        {({ field, form }) => {
                                          const { _id: sub_block_id } = field.value

                                          const medias =
                                            items[index] && items[index].data ? items[index].data[MEDIAS] || [] : []

                                          const { 0: media } = medias || []

                                          const { url, thumbnail_url = '/assets/landscape.svg', isNew, type = IMAGE } =
                                            media || {}

                                          return (
                                            <StyledItemWrapper key={sub_block_id}>
                                              <Flex alignItems="center" justifyContent="space-between">
                                                <Flex alignItems="center">
                                                  <NavigationItemIconWrapper>
                                                    <Icon.Drag
                                                      fill={COLOR_CONSTANTS.COSMIC_ENERGY}
                                                      width="10px"
                                                      height="10px"
                                                    />
                                                  </NavigationItemIconWrapper>
                                                </Flex>

                                                <NavigationItemIconWrapper
                                                  onClick={() => {
                                                    arrayHelpers.remove(index)

                                                    const items_updated = [...items]

                                                    items_updated.splice(index, 1)

                                                    handleChangeBlockComponent({
                                                      page_id,
                                                      block_id,
                                                      key: ITEMS,
                                                      value: items_updated,
                                                    })
                                                  }}
                                                >
                                                  <Image width="8px" height="8px" src="/assets/clear.svg" />
                                                </NavigationItemIconWrapper>
                                              </Flex>

                                              <Flex mt="m" justifyContent="space-between" alignItems="flex-start">
                                                {media ? (
                                                  <MediaWrapper width="80px" height="80px" $border>
                                                    <CloseIconWrapper
                                                      onClick={() => {
                                                        handleChangeBlockComponent({
                                                          page_id,
                                                          block_id,
                                                          sub_block_id,
                                                          key: MEDIAS,
                                                          value: [],
                                                        })

                                                        setFieldValue(`${[ITEMS]}.${index}.data.${MEDIAS}`, [])
                                                      }}
                                                    >
                                                      <Image width="10px" height="10px" src="/assets/clear.svg" />
                                                    </CloseIconWrapper>
                                                    {isNew ? (
                                                      <Flex
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        width="80px"
                                                        height="80px"
                                                      >
                                                        <ClipLoader size="40" color={colors.primary} />
                                                      </Flex>
                                                    ) : (
                                                      <Fragment>
                                                        {type === IMAGE && (
                                                          <ImageWithFallback
                                                            source={thumbnail_url || url}
                                                            fallbackSource={url}
                                                            width="100%"
                                                            height="100%"
                                                            borderRadius={radius.l}
                                                            objectFit="contain"
                                                          />
                                                        )}
                                                      </Fragment>
                                                    )}
                                                  </MediaWrapper>
                                                ) : (
                                                  <Flex />
                                                )}

                                                <MediaUploadComponent
                                                  user={user}
                                                  vistaPage={vistaPage}
                                                  handleChangeBlockComponent={(data) => {
                                                    data.action_data.sub_block_id = sub_block_id
                                                    handleChangeBlockComponent(data)

                                                    const { action_data: { medias = [] } = {} } = data

                                                    setFieldValue(`${[ITEMS]}.${index}.data.${MEDIAS}`, medias)
                                                  }}
                                                  data={{
                                                    page_id,
                                                    block_id,
                                                    medias,
                                                    medias_storage_key: MEDIAS,
                                                  }}
                                                  maxAttachmentsAllowed={1}
                                                />
                                              </Flex>

                                              <Box mt="m">
                                                <Input
                                                  placeholder="Image link"
                                                  type="text"
                                                  {...field}
                                                  value={
                                                    field.value && field.value.data ? field.value.data[LINK] || '' : ''
                                                  }
                                                  onChange={(event) => {
                                                    handleChangeBlockComponent({
                                                      page_id,
                                                      block_id,
                                                      sub_block_id,
                                                      key: LINK,
                                                      value: event.target.value,
                                                    })

                                                    setFieldValue(
                                                      `${[ITEMS]}.${index}.data.${LINK}`,
                                                      event.target.value
                                                    )
                                                  }}
                                                  error={
                                                    form.errors[ITEMS] &&
                                                    form.errors[ITEMS][index] &&
                                                    form.errors[ITEMS][index][LINK]
                                                  }
                                                  width="100%"
                                                />
                                              </Box>

                                              <Box mt="m">
                                                <Input
                                                  placeholder="Alt text"
                                                  type="text"
                                                  {...field}
                                                  value={
                                                    field.value && field.value.data
                                                      ? field.value.data[ALT_TEXT] || ''
                                                      : ''
                                                  }
                                                  onChange={(event) => {
                                                    handleChangeBlockComponent({
                                                      page_id,
                                                      block_id,
                                                      sub_block_id,
                                                      key: ALT_TEXT,
                                                      value: event.target.value,
                                                    })

                                                    setFieldValue(
                                                      `${[ITEMS]}.${index}.data.${ALT_TEXT}`,
                                                      event.target.value
                                                    )
                                                  }}
                                                  error={
                                                    form.errors[ITEMS] &&
                                                    form.errors[ITEMS][index] &&
                                                    form.errors[ITEMS][index][ALT_TEXT]
                                                  }
                                                  width="100%"
                                                />
                                              </Box>
                                            </StyledItemWrapper>
                                          )
                                        }}
                                      </Field>
                                    </Flex>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </Flex>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </form>
                  )}
                />
              </Box>
            )}
          </Formik>
        </Flex>
      ) : (
        <Flex alignItems="center" justifyContent="center" width="100%" flexDirection="column">
          <Grid gridTemplateColumns="repeat(3, 1fr)" gridGap="xs">
            {items.map(({ _id, data = {} }) => {
              const { medias = [], alt_text = '', link = '' } = data || {}
              const { 0: media } = medias || []

              const { url, isNew, type = IMAGE, tempId } = media || {}

              return (
                <StyledFlex
                  key={_id}
                  as={link ? 'a' : 'div'}
                  href={link || ''}
                  target={link ? '_blank' : ''}
                  onClick={() => {
                    handleCollectTrackingData()
                  }}
                  alt={alt_text}
                >
                  {isNew ? (
                    <Flex alignItems="center" justifyContent="center" width="100%" height="100px" position="relative">
                      <ClipLoader size="50" color={colors.primary} />
                      <StyledUploadProgressIndicatorText id={`media-upload-progress-${tempId}`} />
                    </Flex>
                  ) : (
                    <Fragment>
                      {type === IMAGE && (
                        <StyledImageWithFallback
                          source={url || '/assets/landscape.svg'}
                          fallbackSource="/assets/landscape.svg"
                          alt={alt_text}
                        />
                      )}
                    </Fragment>
                  )}
                </StyledFlex>
              )
            })}
          </Grid>
        </Flex>
      )}
    </Fragment>
  )
}

ImageLinksGridComponent.defaultProps = {
  user: {},
  isEditable: false,
  handleChangeBlockComponent: () => {},
  handleCollectTrackingData: () => {},
  isPublic: false,
}

ImageLinksGridComponent.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object.isRequired,
  vistaPage: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  handleChangeBlockComponent: PropTypes.func,
  handleCollectTrackingData: PropTypes.func,
  isPublic: PropTypes.bool,
}

ImageLinksGridComponent.displayName = 'ImageLinksGridComponent'

export default ImageLinksGridComponent
