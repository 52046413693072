import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { Widget } from '@typeform/embed-react'
import { COLOR_CONSTANTS, colors, fontSizes, radius, space } from 'theme'
import { Box, Flex } from 'components/atoms/Layout'
import Input from 'components/atoms/Input'
import Icon from 'components/atoms/Icon'
import Image from 'components/atoms/Image'
import { Text } from 'components/atoms/Typography'

const StyledAccordion = styled(Accordion)`
  padding: 0;

  .MuiAccordionDetails-root {
    padding: 0;
    color: ${colors.primaryText};
  }
`

const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 0 ${space.m} !important;
  min-height: 48px !important;
  background-color: ${COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL} !important;

  .MuiAccordionSummary-content {
    margin: ${space.m} 0 !important;
    color: ${colors.primaryText} !important;
  }
`

const StyledArrowWrapper = styled(Flex)`
  width: 32px;
  height: 32px;
  border-radius: ${radius.pill};
  cursor: pointer;
  background-color: ${COLOR_CONSTANTS.WHITE};
  align-items: center;
  justify-content: center;
  &:hover {
    svg {
      fill: ${colors.primary};
    }
  }
`

const StyledComponentWrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
  background-color: ${COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL};
`

const StyledList = styled.ul`
  margin: ${space.xs} 0 0 0;
  padding: 0 ${space.xs};
  list-style-position: inside;
`

const StyledListItem = styled.li`
  display: list-item;
  text-align: -webkit-match-parent;
  font-size: ${fontSizes.xs};
`

const TIPS = [
  {
    title: `You can find <form-id> from the public URL of your form:`,
    description: `https://form.typeform.com/to/<form-id>`,
  },
  {
    title: `Or from admin panel URL:`,
    description: `https://admin.typeform.com/form/<form-id>/`,
  },
]

const { ID } = {
  ID: 'id',
}

const FormValidationSchema = Yup.object().shape({
  [ID]: Yup.string()
    .required('Typeform identifier is required')
    .max(50, `Identifier is too long - should be 50 chars maximum.`),
})

const TypeformComponent = ({ data, isEditable, handleChangeBlockComponent }) => {
  const { page_id, _id: block_id, data: { id = '' } = {} } = data

  return (
    <Fragment>
      {isEditable ? (
        <Flex flexDirection="column" width="100%" height="100%">
          <Formik
            initialValues={{
              [ID]: id,
            }}
            validationSchema={FormValidationSchema}
            onSubmit={() => {}}
            autocomplete="off"
            validateOnBlur
          >
            {({ values, errors, setFieldValue }) => (
              <Box width="100%">
                <Input
                  placeholder="Typeform identifier"
                  type="text"
                  defaultValue={values[ID]}
                  onChange={(event) => {
                    handleChangeBlockComponent({ page_id, block_id, key: ID, value: event.target.value })
                    setFieldValue(ID, event.target.value)
                  }}
                  error={values[ID] && errors[ID]}
                  width="100%"
                />
              </Box>
            )}
          </Formik>
          <Flex flexDirection="column" width="100%" mt="m">
            <StyledAccordion>
              <StyledAccordionSummary
                expandIcon={
                  <StyledArrowWrapper>
                    <Icon.VistaSocialChevronDown />
                  </StyledArrowWrapper>
                }
                aria-controls="typeform-prompt-content"
                id="typeform-prompt-header"
              >
                <Flex alignItems="center">
                  <Image source="/assets/magic_wand.svg" width="30px" height="30px" />
                  <Flex ml="xs" flexDirection="column">
                    <Text fontSize="xxs" color="secondaryText">
                      Pro Tip
                    </Text>
                    <Text fontSize="xs">How to get form id of your form?</Text>
                  </Flex>
                </Flex>
              </StyledAccordionSummary>
              <AccordionDetails>
                <StyledComponentWrapper px="m">
                  {TIPS.map(({ title, description }, index) => (
                    <Flex pb="m" flexDirection="column" key={index}>
                      <Text>{title}</Text>
                      <StyledList>
                        <StyledListItem>
                          <Text fontSize="xs" as="span">
                            {description}
                          </Text>
                        </StyledListItem>
                      </StyledList>
                    </Flex>
                  ))}
                </StyledComponentWrapper>
              </AccordionDetails>
            </StyledAccordion>
          </Flex>
        </Flex>
      ) : (
        <Flex alignItems="center" justifyContent="center">
          {id && <Widget id={id} style={{ width: '100%', height: '570px' }} />}
        </Flex>
      )}
    </Fragment>
  )
}

TypeformComponent.defaultProps = {
  isEditable: false,
  handleChangeBlockComponent: () => {},
}

TypeformComponent.propTypes = {
  data: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  handleChangeBlockComponent: PropTypes.func,
}

TypeformComponent.displayName = 'TypeformComponent'

export default TypeformComponent
