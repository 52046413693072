import React from 'react'
import debounce from 'lodash.debounce'
import Alert from 'react-s-alert'
import { COLOR_CONSTANTS, colors } from 'theme'
import { ERROR_MESSAGE } from 'consts'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { Box } from 'components/atoms/Layout'
import {
  VISTA_PAGE_BLOCK_TYPE_HEADER,
  VISTA_PAGE_BLOCK_TYPE_BUTTON_LINK,
  VISTA_PAGE_BLOCK_TYPE_TEXT,
  VISTA_PAGE_BLOCK_TYPE_SOCIAL_LINKS,
  VISTA_PAGE_BLOCK_TYPE_IMAGE_LINK,
  VISTA_PAGE_BLOCK_TYPE_YOUTUBE_VIDEO,
  VISTA_PAGE_BLOCK_TYPE_SPOTIFY_MUSIC,
  VISTA_PAGE_BLOCK_TYPE_IMAGE_LINKS_GRID,
  VISTA_PAGE_BLOCK_TYPE_PUBLICATIONS,
  VISTA_PAGE_BLOCK_TYPE_WATERMARK,
  VISTA_PAGE_BLOCK_TYPE_TYPEFORM,
  VISTA_PAGE_BLOCK_TYPE_CALENDLY,
  VISTA_PAGE_BLOCK_TYPE_QR_CODE,
  PAGE_LAST_SAVED_CLIPLOADER_ID,
  PAGE_LAST_SAVED_TEXT,
  ROUTE_VISTA_PAGE,
} from './consts'
import HeaderComponent from './VistaPageDetail/components/VistaPageBlocks/HeaderComponent'
import ButtonLinkComponent from './VistaPageDetail/components/VistaPageBlocks/ButtonLinkComponent'
import TextComponent from './VistaPageDetail/components/VistaPageBlocks/TextComponent'
import SocialLinksComponent from './VistaPageDetail/components/VistaPageBlocks/SocialLinksComponent'
import ImageLinkComponent from './VistaPageDetail/components/VistaPageBlocks/ImageLinkComponent'
import YouTubeComponent from './VistaPageDetail/components/VistaPageBlocks/YouTubeComponent'
import SpotifyComponent from './VistaPageDetail/components/VistaPageBlocks/SpotifyComponent'
import ImageLinksGridComponent from './VistaPageDetail/components/VistaPageBlocks/ImageLinksGridComponent'
import PublicationsComponent from './VistaPageDetail/components/VistaPageBlocks/PublicationsComponent'
import WatermarkComponent from './VistaPageDetail/components/VistaPageBlocks/WatermarkComponent'
import TypeformComponent from './VistaPageDetail/components/VistaPageBlocks/TypeformComponent'
import CalendlyComponent from './VistaPageDetail/components/VistaPageBlocks/CalendlyComponent'
import QrCodeComponent from "./VistaPageDetail/components/VistaPageBlocks/QrCodeComponent"

export const getBackgroundStyleString = ({ background_color, background_media, type = '' }) => {
  let style = ''

  if (background_color) {
    if (background_color.charAt(0) === '#' || background_color.startsWith('rgb')) {
      if (type === 'text') {
        style = `color: ${background_color}`
      } else {
        style = `background-color: ${background_color}`
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (type === 'text') {
        style = `-webkit-text-fill-color: transparent;
      background-clip: text; background-image: ${background_color}`
      } else {
        style = `background-image: ${background_color}`
      }
    }
  } else if (background_media) {
    style = `background-image: url(${background_media});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover`
  }

  return style
}

export const getTextFontStyleString = ({ style }) => {
  let text_font_style = 'font-style: normal'

  if (style) {
    text_font_style = 'font-style: italic'
  }

  return text_font_style
}

export const getTextFontWeightString = ({ weight }) => {
  let text_font_weight = ''

  if (weight) {
    text_font_weight = `font-weight: ${weight}`
  }
  return text_font_weight
}

export const getVistaPageBlockByType = ({ type }) => {
  if (!type) {
    return <Box />
  }

  switch (type) {
    case VISTA_PAGE_BLOCK_TYPE_HEADER:
      return HeaderComponent
    case VISTA_PAGE_BLOCK_TYPE_BUTTON_LINK:
      return ButtonLinkComponent
    case VISTA_PAGE_BLOCK_TYPE_TEXT:
      return TextComponent
    case VISTA_PAGE_BLOCK_TYPE_SOCIAL_LINKS:
      return SocialLinksComponent
    case VISTA_PAGE_BLOCK_TYPE_IMAGE_LINK:
      return ImageLinkComponent
    case VISTA_PAGE_BLOCK_TYPE_YOUTUBE_VIDEO:
      return YouTubeComponent
    // case VISTA_PAGE_BLOCK_TYPE_SPOTIFY_MUSIC:
    //   return SpotifyComponent
    case VISTA_PAGE_BLOCK_TYPE_IMAGE_LINKS_GRID:
      return ImageLinksGridComponent
    case VISTA_PAGE_BLOCK_TYPE_PUBLICATIONS:
      return PublicationsComponent
    case VISTA_PAGE_BLOCK_TYPE_TYPEFORM:
      return TypeformComponent
    case VISTA_PAGE_BLOCK_TYPE_CALENDLY:
      return CalendlyComponent
    case VISTA_PAGE_BLOCK_TYPE_WATERMARK:
      return WatermarkComponent
    case VISTA_PAGE_BLOCK_TYPE_QR_CODE:
      return QrCodeComponent
    default:
      return <Box />
  }
}

export const handleSaveVistaPage = async ({ vistaPage }) => {
  const { _id, appearance, pages } = vistaPage

  let saved_text_options = {
    html: 'Changes saved',
    color: colors.secondaryText,
  }

  const saved_text_el = document.getElementById(PAGE_LAST_SAVED_TEXT)

  if (saved_text_el) {
    saved_text_el.style.display = 'none'
  }

  const cliploaader_el = document.getElementById(PAGE_LAST_SAVED_CLIPLOADER_ID)

  if (cliploaader_el) {
    cliploaader_el.style.display = 'flex'
  }

  try {
    const body = {
      appearance,
      pages,
    }

    const response = await request({
      method: 'PATCH',
      path: `${ROUTE_VISTA_PAGE}/${_id}`,
      body,
    })

    if (cliploaader_el) {
      cliploaader_el.style.display = 'none'
    }

    const { error } = response || {}

    if (error || !response) {
      Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      saved_text_options = {
        html: 'Changes not saved',
        color: colors.error,
      }
    }
  } catch (error) {
    errorHelper({ error, componentName: 'VistaPage/helper', functionName: 'handleSaveVistaPage' })
    saved_text_options = {
      html: 'Changes not saved',
      color: colors.error,
    }
  } finally {
    if (saved_text_el) {
      saved_text_el.style.display = 'block'
      saved_text_el.innerHTML = saved_text_options.html
      saved_text_el.style.color = saved_text_options.color

      setTimeout(() => {
        saved_text_el.style.display = 'none'
      }, 500)
    }
  }
}

export const handleSaveVistaPageDebounce = debounce(({ vistaPage }) => {
  handleSaveVistaPage({ vistaPage })
}, 1000)

export const getCustomDomainStatusAndColor = ({ status }) => {
  let status_color = colors.error

  let status_updated = status || ''

  if (status) {
    if (status === 'ISSUED' || status === 'SUCCESS') {
      status_color = COLOR_CONSTANTS.JUNGLE_GREEN
    } else if (status.includes('PENDING')) {
      status_color = colors.warning
      status_updated = 'Pending'
    } else if (status.includes('VALIDATION')) {
      status_updated = 'Failed'
    }
  }

  return {
    status_color,
    status: status_updated ? `${status_updated.charAt(0).toUpperCase()}${status_updated.substr(1).toLowerCase()}` : '',
  }
}

export const collectItemsForInternalLinks = ({ vistaPage, page_id, block_id }) => {
  const items_transformed = []

  const { pages = [] } = vistaPage

  const foundPage = page_id ? pages.find(({ _id }) => _id === page_id) : null

  const { items = [] } = foundPage || {}

  items.forEach((item) => {
    const { _id, isVisible, data: { label = '', description = '', text = '' } = {} } = item
    if (isVisible && _id !== block_id) {
      items_transformed.push({ value: item._id, label: label || description || text || '', type: item.type })
    }
  })
  return { items_transformed }
}
