import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import dynamic from 'next/dynamic'
import { COLOR_CONSTANTS, colors, fontSizes } from 'theme'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Switch from 'components/atoms/Switch'
import {
  TEXT_THEME,
  DEFAULT_THEME_CONTENT_WIDTH,
  DEFAULT_THEME_CORNER_RADIUS,
  DEFAULT_THEME_SCALE,
  DEFAULT_THEME_SHADOW,
  DEFAULT_THEME_TRANSPARENCY,
  VISTA_PAGE_THEME_TEMPLATE_DEFAULT,
  SCALE_CONSTANT,
  DEFAULT_THEME_TEXT_FONT_WEIGHT,
  DEFAULT_THEME_TEXT_FONT_STYLE,
} from 'routes/VistaPage/consts'
// eslint-disable-next-line import/no-cycle
import { getBackgroundStyleString, getTextFontStyleString, getTextFontWeightString } from 'routes/VistaPage/helper'
import ThemeComponent from 'routes/VistaPage/VistaPageDetail/components/ThemeComponent'

const ReactQuill = dynamic(import('react-quill'), { ssr: false })

const StyledApplyDefaultThemeText = styled(Text)`
  color: ${colors.primary};
  font-size: ${fontSizes.xs};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const StyledTextAreaWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: ${({ $fontSize }) => $fontSize}em;
  box-shadow: rgb(145 147 169 / 85%) 0px 0px ${({ $boxShadow }) => $boxShadow}em;
  overflow: hidden;
  cursor: pointer;
  position: relative;
`

const StyledTextAreaBackground = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  ${({ $background }) => $background};
  opacity: ${({ $transparency }) => ($transparency ? 1 - $transparency : 1)};
`

const StyledText = styled(Box)`
  box-sizing: border-box;
  white-space: break-spaces;
  z-index: 0;
  ${({ $color }) => $color};
  ${({ $fontStyle }) => $fontStyle};
  ${({ $fontWeight }) => $fontWeight};
  & > p {
    margin: 0;
  }
`

const editorModules = {
  toolbar: [
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] }],
    ['link'],
    ['clean'],
  ],
  clipboard: {
    matchVisual: false,
  },
}

const editorFormats = [
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'align',
]

const { TEXT, DETAILED_APPEARANCE_THEME } = {
  TEXT: 'text',
  DETAILED_APPEARANCE_THEME: 'detailed_appearance_theme',
}

const FormValidationSchema = Yup.object().shape({
  [TEXT]: Yup.string().max(1000, `Text is too long - should be 1000 chars maximum.`),
})

const TextComponent = ({ data, vistaPage, isEditable, handleChangeBlockComponent }) => {
  const { page_id, _id: block_id, data: { text = '', detailed_appearance_theme = false } = {} } = data

  const { appearance = VISTA_PAGE_THEME_TEMPLATE_DEFAULT } = vistaPage

  const appearance_theme = appearance[TEXT_THEME] || {}

  const theme = data.data ? data.data[TEXT_THEME] || appearance_theme : appearance_theme

  const has_theme_changed = !!(data.data && data.data[TEXT_THEME])

  const {
    background = appearance_theme.background,
    text_scale = appearance_theme.scale || DEFAULT_THEME_SCALE,
    text_color = appearance_theme.text_color,
    text_font_weight = appearance_theme.text_font_weight || DEFAULT_THEME_TEXT_FONT_WEIGHT,
    text_font_style = appearance_theme.text_font_style || DEFAULT_THEME_TEXT_FONT_STYLE,
    content_width = appearance_theme.content_width || DEFAULT_THEME_CONTENT_WIDTH,
    corner_radius = appearance_theme.corner_radius || DEFAULT_THEME_CORNER_RADIUS,
    shadow = appearance_theme.shadow || DEFAULT_THEME_SHADOW,
    transparency = appearance_theme.transparency || DEFAULT_THEME_TRANSPARENCY,
  } = theme || {}

  const { background_color } = background || {}
  const textarea_background = getBackgroundStyleString({ background_color })

  const { color } = text_color || {}
  const text_font_color = getBackgroundStyleString({ background_color: color, type: 'text' })
  const text_font_style_string = getTextFontStyleString({ style: text_font_style })
  const text_font_weight_string = getTextFontWeightString({ weight: text_font_weight })

  return (
    <Fragment>
      {isEditable ? (
        <Flex flexDirection="column" width="100%" height="100%">
          <Formik
            initialValues={{
              [TEXT]: text,
            }}
            validationSchema={FormValidationSchema}
            onSubmit={() => {}}
            autocomplete="off"
            validateOnBlur
          >
            {({ values, setFieldValue }) => (
              <Box width="100%">
                <Box>
                  <ReactQuill
                    theme="snow"
                    defaultValue={values[TEXT]}
                    placeholder="Type ..."
                    modules={editorModules}
                    formats={editorFormats}
                    onChange={(value) => {
                      handleChangeBlockComponent({ page_id, block_id, key: TEXT, value })
                      setFieldValue(TEXT, value)
                    }}
                  />
                </Box>
              </Box>
            )}
          </Formik>

          <Flex mt="l" alignItems="center" justifyContent="space-between">
            <Switch
              isOn={detailed_appearance_theme}
              onClick={() => {
                handleChangeBlockComponent({
                  page_id,
                  block_id,
                  key: DETAILED_APPEARANCE_THEME,
                  value: !detailed_appearance_theme,
                })
              }}
              text="Edit theme"
              color={COLOR_CONSTANTS.DENIM}
              fontWeight="normal"
            />

            {detailed_appearance_theme && has_theme_changed && (
              <StyledApplyDefaultThemeText
                onClick={() => {
                  handleChangeBlockComponent({ page_id, block_id, key: TEXT_THEME, value: null })
                }}
              >
                Apply default page theme
              </StyledApplyDefaultThemeText>
            )}
          </Flex>

          {detailed_appearance_theme && (
            <Flex flexDirection="column" mt="m">
              <ThemeComponent
                handleChangeParameter={({ key, value }) => {
                  handleChangeBlockComponent({ page_id, block_id, type: TEXT_THEME, key, value })
                }}
                data={theme}
                showBackgroundColorSettingsPaletteTypeMedia={false}
                showHeaderFormatSettings={false}
                showHeaderMediasScaleSettings={false}
                showSocialLinksColoredSettings={false}
                showSocialLinksImagesScaleSettings={false}
                showImageLinkImageScaleSettings={false}
              />
            </Flex>
          )}
        </Flex>
      ) : (
        <Flex alignItems="center" justifyContent="center">
          <StyledTextAreaWrapper
            p={`${20 * SCALE_CONSTANT}px`}
            width={`${content_width}%`}
            $fontSize={text_scale}
            borderRadius={`${corner_radius}em`}
            $boxShadow={shadow}
          >
            <StyledTextAreaBackground $background={textarea_background} $transparency={transparency} />
            <StyledText
              fontSize={`${0.95}em`}
              textAlign="center"
              $color={text_font_color}
              $fontWeight={text_font_weight_string}
              $fontStyle={text_font_style_string}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          </StyledTextAreaWrapper>
        </Flex>
      )}
    </Fragment>
  )
}

TextComponent.defaultProps = {
  isEditable: false,
  handleChangeBlockComponent: () => {},
}

TextComponent.propTypes = {
  data: PropTypes.object.isRequired,
  vistaPage: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  handleChangeBlockComponent: PropTypes.func,
}

TextComponent.displayName = 'ButtonLinkComponent'

export default TextComponent
