import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components'
import Head from 'next/head'
import { fluidRange } from 'polished'
import TagManager from 'react-gtm-module'
import { COLOR_CONSTANTS, colors, fontStack, radius, space, theme } from 'theme'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import withoutPageDataProps from 'utils/withoutPageDataProps'
import ErrorBoundary from 'ErrorBoundary'
import { Box, Flex } from 'components/atoms/Layout'
import {
  ROUTE_VISTA_PAGE,
  VISTA_PAGE_THEME_TEMPLATE_DEFAULT,
  VISTA_PAGE_FONT_POPPINS,
  VISTA_PAGE_FONT_FAMILIES_WEIGHT,
  VISTA_PAGE_BLOCK_TYPE_WATERMARK,
  PAGE_BLOCK_ID_START,
} from 'routes/VistaPage/consts'
import { getBackgroundStyleString, getVistaPageBlockByType } from 'routes/VistaPage/helper'
import { TEMPORARY_VISTA_PAGE } from 'routes/VistaPage/templates'
import PublicPageControlsComponent from './components/PublicPageControlsComponent'
import { ANALYTICS_METRIC_CLICKS } from './consts'

import 'sanitize.css'

const GlobalStyles = createGlobalStyle`
  html, body, #__next {
    height:100%;
  }
  html {
    ${fluidRange({
      prop: 'font-size',
      fromSize: '14px',
      toSize: '18px',
    })}
  }
  body {
    font-family: ${fontStack};
    font-weight: 400;
    color: ${colors.primaryText};
    background-color: ${COLOR_CONSTANTS.DAISY};
    min-width: 320px;
    overflow-x: hidden;
  }
  body,
  button,
  input,
  textarea,
  select {
    font-family: ${fontStack};
  }
  .calendly-inline-widget { 
    min-width: 100% !important;
    height: 570px !important;
    background: #ffffff;
    border-radius: ${radius.l};
  }
  .calendly-inline-widget iframe, .calendly-badge-widget iframe, .calendly-overlay iframe {
      border-radius: ${radius.l};
  }
`

const StyledBodyWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  padding: ${space.m};
  width: 100%;
  min-height: 100%;
  font-family: ${({ $bodyFont }) => `${$bodyFont || VISTA_PAGE_FONT_POPPINS}, sans-serif`};
`

const StyledBackground = styled(Box)`
  position: fixed;
  inset: 0px;
  z-index: -1;
  ${({ $backgroundStyle }) => $backgroundStyle && $backgroundStyle};
`

const StyledItemsWrapper = styled(Box)`
  flex: 1 0 auto;
  width: 100%;
  max-width: 650px;
  position: relative;
`

const VistaPagePublic = ({ data, isTemporary }) => {
  const handleTrackAnalyticsMetrics = ({ block_id, metric }) => {
    // saving analytics metrics for vista page
    if (!isTemporary && data.domain) {
      request({
        method: 'POST',
        path: `${ROUTE_VISTA_PAGE}/${data.domain}/track`,
        body: {
          item_id: block_id, // block_id can be not specified
          metric: metric || ANALYTICS_METRIC_CLICKS, // metric is required
        },
      })
    }
  }

  const getFonts = async () => {
    if (process.browser) {
      const { appearance = VISTA_PAGE_THEME_TEMPLATE_DEFAULT, gtm_id = '', isPreview = false } = data

      const { font } = appearance || {}
      const { header, body } = font || {}

      const fonts = [header, body].filter((family) => family)

      if (fonts.length !== 0) {
        const WebFontLoader = await import('webfontloader')

        const families = fonts.map((family) => {
          return `${family}${
            VISTA_PAGE_FONT_FAMILIES_WEIGHT[family] ? `:${VISTA_PAGE_FONT_FAMILIES_WEIGHT[family]}` : ''
          }`
        })

        WebFontLoader.load({
          google: {
            families,
          },
        })
      }

      if (gtm_id && !isPreview && !isTemporary) {
        console.log('enabling gtm', gtm_id)
        TagManager.initialize({
          gtmId: gtm_id,
        })
      }
    }
  }

  useEffect(() => {
    getFonts()

    // we don't call handleTrackAnalyticsMetrics onload because this is done on the server (for views counting)
  }, [])

  useEffect(() => {
    const script = document.createElement('script')

    script.src = '/js/vista_page.js'
    script.async = true
    script.crossOrigin = 'anonymous'

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const { appearance = VISTA_PAGE_THEME_TEMPLATE_DEFAULT, pages = [], active_page_id, watermark = true } = data

  const foundPage = active_page_id ? pages.find(({ _id }) => _id === active_page_id) : null

  const { items = [] } = foundPage || {}

  const { font, background } = appearance || {}
  const { body: bodyFont } = font || {}
  const { background_color, background_media } = background || {}
  const { url: background_media_url } = background_media || {}

  const backgroundStyle = getBackgroundStyleString({ background_color, background_media: background_media_url })

  let WatermarkComponent = null
  if (watermark) {
    WatermarkComponent = getVistaPageBlockByType({ type: VISTA_PAGE_BLOCK_TYPE_WATERMARK })
  }

  const meta_description = data.description || `${data.name} vista page made with Vista Social`

  return (
    <ErrorBoundary>
      <Head>
        <title>{data.name}</title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="description" content={meta_description} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0,maximum-scale=1.0" />
        <meta name="title" content={data.name} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={data.name} />
        <meta property="og:description" content={meta_description} />
        <meta property="og:image" content={data.picture_url} />
        <meta property="og:site_name" content={data.name} />
        <meta property="og:image:alt" content={data.name} />
        <link rel="image_src" href={data.picture_url} />
        <link rel="shortcut icon" type="image/x-icon" href={data.picture_url || '/assets/favicon.ico?v=1'} />
      </Head>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <StyledBodyWrapper $bodyFont={bodyFont}>
          {backgroundStyle && <StyledBackground $backgroundStyle={backgroundStyle} />}
          <StyledItemsWrapper>
            {items.map((block) => {
              const { _id: block_id, type, isVisible } = block

              if (!isVisible) {
                return null
              }

              const BlockComponentPreview = getVistaPageBlockByType({ type })

              return (
                <Flex flexDirection="column" key={block_id} mb="m" id={`${PAGE_BLOCK_ID_START}${block_id}`}>
                  <BlockComponentPreview
                    data={block}
                    vistaPage={data}
                    isPublic
                    handleCollectTrackingData={() => {
                      handleTrackAnalyticsMetrics({
                        block_id,
                        metric: ANALYTICS_METRIC_CLICKS,
                      })
                    }}
                  />
                </Flex>
              )
            })}

            <Flex flexDirection="column" my="m" position="relative" minHeight="32px">
              {watermark && <WatermarkComponent />}
              <Box position="absolute" top={space.s} right={space.s} zIndex="1">
                <PublicPageControlsComponent
                  vistaPage={data}
                  isTemporary={isTemporary}
                  handleTrackAnalyticsMetrics={handleTrackAnalyticsMetrics}
                />
              </Box>
            </Flex>
          </StyledItemsWrapper>
        </StyledBodyWrapper>
      </ThemeProvider>
    </ErrorBoundary>
  )
}

VistaPagePublic.defaultProps = {
  isTemporary: false,
}

VistaPagePublic.propTypes = {
  data: PropTypes.object.isRequired,
  isTemporary: PropTypes.bool,
}

VistaPagePublic.getInitialProps = async (context) => {
  try {
    // eslint-disable-next-line prefer-const
    let { id, domain } = context.query

    const { req, res } = context

    if (!domain && !id && req) {
      domain = req.headers['x-forwarded-domain']
    }

    if (domain === 'vista.page') {
      if (res) {
        return res.redirect('https://vistasocial.com/vista-page/')
      }
    }

    const api_url = process.env.NEXT_PUBLIC_VISTAPAGE_BACKEND_URL

    // console.log(`getting vista page public page domain = ${domain} or id = ${id}, api url ${api_url}`)

    const response = await request({
      api_url,
      context,
      path: `${ROUTE_VISTA_PAGE}/public${domain ? `?domain=${domain}` : `${id ? `?id=${id}` : ''}`}`,
    })

    const { error, data } = response || {}

    if (!response || error) {
      return { data: TEMPORARY_VISTA_PAGE, isTemporary: true }
    } else {
      return { data }
    }
  } catch (error) {
    errorHelper({
      error,
      componentName: VistaPagePublic.displayName,
      functionName: 'getInitialProps',
      showAlert: false,
    })
    return { data: TEMPORARY_VISTA_PAGE, isTemporary: true }
  }
}

VistaPagePublic.displayName = 'VistaPagePublic'

export default withoutPageDataProps(VistaPagePublic)
