import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space as styledSpace } from 'styled-system'
import { transparentize } from 'polished'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { COLOR_CONSTANTS, colors, radius, space } from 'theme'
import { FACEBOOK, LINKEDIN, TWITTER } from 'consts'
import { Box, Flex } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import { Text } from 'components/atoms/Typography'
import Icon from 'components/atoms/Icon'
import { VISTA_PAGE_DOMAIN_ENDING } from 'routes/VistaPage/consts'
import { ANALYTICS_METRIC_COPIES, ANALYTICS_METRIC_SHARES } from '../consts'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${transparentize(0.2, COLOR_CONSTANTS.SALUTE)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 600px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    height: auto;
    display: flex;
    flex-direction: column;
  }
`

const StyledDialogBodyWrapper = styled(Flex)`
  height: 100%;
  overflow: hidden;
  outline: none;
`

const StyledShareNetworksWrapper = styled(Flex)`
  gap: ${space.m};
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`

const StyledNetworkImage = styled(Image)`
  cursor: pointer;
`

const StyledButtonsWrapper = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${space.m};
  border: 1px solid rgb(224, 224, 224);
  border-radius: ${radius.l};
  cursor: pointer;
  margin-top: ${space.m};
  text-decoration: none;
  color: ${colors.primaryText};
  &:hover {
    background: ${COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL};
  }
`

const StyledIconCopy = styled(Icon.Copy)`
  fill: ${COLOR_CONSTANTS.COSMIC_ENERGY};
  width: 16px;
  height: 16px;
`

const StyledIconFlag = styled(Icon.Flag)`
  fill: ${COLOR_CONSTANTS.COSMIC_ENERGY};
  width: 16px;
  height: 18px;
`

const StyledIconFlare = styled(Icon.Flare)`
  fill: ${COLOR_CONSTANTS.COSMIC_ENERGY};
  width: 16px;
  height: 18px;
`

const StyledIconVistaSocialChevronRight = styled(Icon.VistaSocialChevronRight)`
  fill: ${COLOR_CONSTANTS.COSMIC_ENERGY};
  width: 10px;
  height: 10px;
`

const { WHATSAPP, EMAIL } = { WHATSAPP: 'whatsapp', EMAIL: 'email' }

const SHARE_NETWORKS = [
  {
    network: FACEBOOK,
  },
  {
    network: LINKEDIN,
  },
  {
    network: TWITTER,
  },
  {
    network: WHATSAPP,
  },
  {
    network: EMAIL,
  },
]

const PublicPageControlsModal = ({ isOpen, handleDismiss, vistaPage, isTemporary, handleTrackAnalyticsMetrics }) => {
  const { domain, custom_domain } = vistaPage
  const { name: custom_domain_name = '' } = custom_domain || {}

  const generateDomainLink = () => {
    let domain_updated = `https://${domain}${VISTA_PAGE_DOMAIN_ENDING}`

    if (custom_domain_name) {
      domain_updated = `https://${custom_domain_name}`
    }

    return domain_updated
  }

  const handleGenerateShareLink = ({ network }) => {
    let link = ''

    const domain_updated = `${generateDomainLink()}?utm_source=vita_page_share`

    const share_text = `Check out this Vista Page!`

    if (network === FACEBOOK) {
      link = `https://www.facebook.com/sharer.php?u=${domain_updated}`
    } else if (network === LINKEDIN) {
      link = `https://www.linkedin.com/sharing/share-offsite/?url=${domain_updated}`
    } else if (network === TWITTER) {
      link = `https://x.com/intent/tweet?text=${share_text} ${domain_updated}`
    } else if (network === WHATSAPP) {
      link = `https://wa.me/?text=${share_text} ${domain_updated}`
    } else if (network === EMAIL) {
      link = `mailto:?subject=${share_text}&body=${share_text} ${domain_updated}`
    }

    return link
  }

  const handleClickCopyLink = async () => {
    await navigator.clipboard.writeText(generateDomainLink())

    handleTrackAnalyticsMetrics({
      metric: ANALYTICS_METRIC_COPIES,
    })

    const el = document.getElementById('vista-page-copy-link')

    if (el) {
      el.innerText = `Vista Page link copied!`
    }

    setTimeout(() => {
      const el = document.getElementById('vista-page-copy-link')

      if (el) {
        el.innerText = `Copy the link`
      }
    }, 2000)
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={handleDismiss}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledDialogBodyWrapper py="xl" px="m" flex="1" alignItems="center" flexDirection="column" tabIndex="0">
            <Text fontSize="xxl" fontWeight="bold" textAlign="center">
              Share this Vista Page 🤗
            </Text>
            <Text fontSize="m" textAlign="center">
              Share this Vista Page with your friends and followers!
            </Text>
            <StyledShareNetworksWrapper mt="l">
              {SHARE_NETWORKS.map(({ network }) => {
                const link = handleGenerateShareLink({ network })
                return (
                  <Text
                    as="a"
                    href={link}
                    key={network}
                    width="32px"
                    height="32px"
                    onClick={() => {
                      handleTrackAnalyticsMetrics({
                        metric: ANALYTICS_METRIC_SHARES,
                      })
                    }}
                    target="_blank"
                  >
                    <StyledNetworkImage
                      key={network}
                      src={`/assets/vista_page/networks/${network}.svg`}
                      width="100%"
                      height="100%"
                    />
                  </Text>
                )
              })}
            </StyledShareNetworksWrapper>

            <Flex mt="m" flexDirection="column" width="100%" maxWidth="400px">
              <StyledButtonsWrapper mt="m" onClick={handleClickCopyLink}>
                <Flex alignItems="center">
                  <StyledIconCopy />
                  <Text ml="s" id="vista-page-copy-link">
                    Copy the link
                  </Text>
                </Flex>
                <StyledIconVistaSocialChevronRight />
              </StyledButtonsWrapper>

              {!isTemporary && (
                <StyledButtonsWrapper
                  mt="m"
                  onClick={() => {
                    window.open(
                      `https://form.typeform.com/to/sHRQcJxU?vista_page_url=${custom_domain_name ||
                        `${domain}${VISTA_PAGE_DOMAIN_ENDING}`}`,
                      '_blank'
                    )
                  }}
                >
                  <Flex alignItems="center">
                    <StyledIconFlag />
                    <Text ml="s">Report this Vista Page</Text>
                  </Flex>
                  <StyledIconVistaSocialChevronRight />
                </StyledButtonsWrapper>
              )}

              <StyledButtonsWrapper
                mt="m"
                onClick={() => {
                  window.open(`https://vista.page`, '_blank')
                }}
              >
                <Flex alignItems="center">
                  <StyledIconFlare />
                  <Text ml="s">Create your own Vista Page</Text>
                </Flex>
                <StyledIconVistaSocialChevronRight />
              </StyledButtonsWrapper>
            </Flex>
          </StyledDialogBodyWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

PublicPageControlsModal.defaultProps = {}

PublicPageControlsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  vistaPage: PropTypes.object.isRequired,
  isTemporary: PropTypes.bool.isRequired,
  handleTrackAnalyticsMetrics: PropTypes.func.isRequired,
}

PublicPageControlsModal.displayName = 'PublicPageControlsModal'

export default PublicPageControlsModal
