import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Slider } from '@material-ui/core'
import Alert from 'react-s-alert'
import { transparentize } from 'polished'
import { COLOR_CONSTANTS, colors, radius, space } from 'theme'
import { IMAGE } from 'consts'
import { pxToRem } from 'helpers'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Image from 'components/atoms/Image'
import MediaBodyComponent from 'routes/Media/MediaBodyComponent'
import {
  PALETTE_TYPE_COLOR,
  PALETTE_TYPE_GRADIENT,
  PALETTE_TYPE_MEDIA,
  COLORS_PALETTE,
  GRADIENT_PALETTE,
} from 'routes/Calendar/consts'
import {
  DEFAULT_THEME_CONTENT_WIDTH,
  DEFAULT_THEME_CORNER_RADIUS,
  DEFAULT_THEME_SHADOW,
  DEFAULT_THEME_TRANSPARENCY,
  DEFAULT_THEME_TEXT_FONT_WEIGHT,
  DEFAULT_THEME_TEXT_SCALE,
  DEFAULT_THEME_TEXT_FONT_STYLE,
  DEFAULT_HEADER_MEDIA_SCALE,
  HEADER_FORMAT_WITHOUT_BANNER_VERTICAL,
  HEADER_FORMAT_WITHOUT_BANNER_HORIZONTAL,
  HEADER_FORMAT_WITH_BANNER_VERTICAL_FULL_BACKGROUND,
  HEADER_FORMAT_WITH_BANNER_VERTICAL_MEDIUM_BACKGROUND,
  DEFAULT_SOCIAL_LINKS_COLORED,
  DEFAULT_IMAGE_LINK_IMAGE_SCALE,
  DEFAULT_SOCIAL_LINKS_IMAGES_SCALE,
} from '../../consts'

const Tab = styled(Box)`
  margin-right: ${space.m};
  text-decoration: none;
  cursor: pointer;
  border-bottom: 2px solid ${({ isActive }) => (isActive ? colors.primary : 'transparent')};
  &:visited {
    color: initial;
  }
  &:hover {
    border-bottom: 2px solid ${({ isActive }) => (isActive ? colors.primary : colors.secondaryText)};
  }
`

const StyledInputColor = styled.input`
  width: 100%;
  padding: ${space.xs} ${space.m};
  border: 1px solid ${({ isError }) => (isError ? colors.error : COLOR_CONSTANTS.SOLITUDE)};
  border-radius: ${radius.l};
  height: ${pxToRem(40)};
  background: ${COLOR_CONSTANTS.WHITE};
  cursor: pointer;
`

const StyledColorPaletteItemWrapper = styled(Box)`
  width: 24px;
  height: 24px;
  margin-right: 14px;
  margin-bottom: 14px;
  transform: scale(1);
  transition: transform 100ms ease 0s;
  &:hover {
    transform: scale(1.2);
  }
`

const StyledColorPaletteItem = styled(Box)`
  background: transparent;
  height: 100%;
  width: 100%;
  cursor: pointer;
  position: relative;
  outline: none;
  border-radius: 50%;
  box-shadow: ${({ boxColor }) => boxColor} 0px 0px 0px ${({ selected }) => (selected ? '3px' : '14px')} inset;
  transition: box-shadow 100ms ease 0s;
  border: 1px solid ${({ hasBorder }) => (hasBorder ? COLOR_CONSTANTS.SOLITUDE : 'transparent')};
`

const StyledGradientPaletteItemWrapper = styled(Flex)`
  width: 57px;
  height: 57px;
  border: 1px solid transparent;
  background-image: ${({ backgroundImage }) => backgroundImage};
  cursor: pointer;
  border-radius: ${radius.l};
  ${({ selected }) =>
    selected && `border: 1px solid ${colors.primaryText}; box-shadow: 0px 0px 5px 5px rgb(39 40 49 / 5%);`}
  &:hover {
    box-shadow: 0px 0px 5px 5px rgb(39 40 49 / 5%);
  }
`

const StyledHeaderFormatItemWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  border-radius: ${radius.l};
  ${({ $isSelected }) => $isSelected && `box-shadow: ${COLOR_CONSTANTS.BEE_HALL} 0px 0px 0px 2px`}
`

const WarningMessageWrapper = styled(Flex)`
  background-color: ${transparentize(0.9, colors.warning)};
  border-radius: ${radius.l};
  padding: ${space.s} ${space.m};
`

const PALETTE_TABS_BACKGROUND = [
  { name: 'Background color', type: PALETTE_TYPE_COLOR },
  { name: 'Background gradient color', type: PALETTE_TYPE_GRADIENT },
  { name: 'Background media', type: PALETTE_TYPE_MEDIA },
]

const PALETTE_TABS_TEXT_COLOR = [
  { name: 'Text color', type: PALETTE_TYPE_COLOR },
  { name: 'Text gradient color', type: PALETTE_TYPE_GRADIENT },
]

const HEADER_FORMATS = [
  {
    type: HEADER_FORMAT_WITHOUT_BANNER_VERTICAL,
    image: '/assets/vista_page/header_block/header_no_image_vertical.png',
  },
  {
    type: HEADER_FORMAT_WITHOUT_BANNER_HORIZONTAL,
    image: '/assets/vista_page/header_block/header_no_image_horizontal.png',
  },
  {
    type: HEADER_FORMAT_WITH_BANNER_VERTICAL_FULL_BACKGROUND,
    image: '/assets/vista_page/header_block/header_image_full_vertical.png',
  },
  {
    type: HEADER_FORMAT_WITH_BANNER_VERTICAL_MEDIUM_BACKGROUND,
    image: '/assets/vista_page/header_block/header_image_medium_vertical.png',
  },
]

const ThemeComponent = ({
  user,
  data,
  handleChangeParameter,
  showBackgroundColorSettings,
  showBackgroundColorSettingsPaletteTypeMedia,
  showTextColorSettings,
  showDetailedSettings,
  showContentWidthSettings,
  showCornerRoundnessSettings,
  showShadowSettings,
  showTransparencySettings,
  showTextScaleSettings,
  showTextFontWeightSettings,
  showTextFontStyleSettings,
  showHeaderFormatSettings,
  showHeaderMediasScaleSettings,
  showSocialLinksColoredSettings,
  showSocialLinksImagesScaleSettings,
  showImageLinkImageScaleSettings,
}) => {
  const [activeBackgroundPaletteTab, setActiveBackgroundPaletteTab] = useState(PALETTE_TYPE_COLOR)
  const [activeTextPaletteTab, setActiveTextPaletteTab] = useState(PALETTE_TYPE_COLOR)

  const {
    background,
    content_width = DEFAULT_THEME_CONTENT_WIDTH,
    corner_radius = DEFAULT_THEME_CORNER_RADIUS,
    shadow = DEFAULT_THEME_SHADOW,
    transparency = DEFAULT_THEME_TRANSPARENCY,
    text_color,
    text_scale = DEFAULT_THEME_TEXT_SCALE,
    text_font_weight = DEFAULT_THEME_TEXT_FONT_WEIGHT,
    text_font_style = DEFAULT_THEME_TEXT_FONT_STYLE,
    header_format = HEADER_FORMAT_WITHOUT_BANNER_VERTICAL,
    header_media_scale = DEFAULT_HEADER_MEDIA_SCALE,
    social_links_colored = DEFAULT_SOCIAL_LINKS_COLORED,
    image_link_image_scale = DEFAULT_IMAGE_LINK_IMAGE_SCALE,
    social_links_image_scale = DEFAULT_SOCIAL_LINKS_IMAGES_SCALE,
    entity = {},
  } = data || {}

  const { background_color, background_type } = background || {}
  const { color: text_font_color, color_type } = text_color || {}

  useEffect(() => {
    if (background_type && background_type !== PALETTE_TYPE_COLOR) {
      setActiveBackgroundPaletteTab(background_type)
    }
    if (color_type && color_type !== PALETTE_TYPE_COLOR) {
      setActiveTextPaletteTab(background_type)
    }
  }, [])

  let inputBackgroundColor = colors.black

  if (background_color && background_color.charAt(0) === '#') {
    inputBackgroundColor = background_color
  }

  let inputTextColor = colors.black

  if (text_font_color && text_font_color.charAt(0) === '#') {
    inputTextColor = text_font_color
  }

  return (
    <Fragment>
      {showBackgroundColorSettings && (
        <Fragment>
          <Flex height={showBackgroundColorSettingsPaletteTypeMedia ? '51px' : '30px'} alignItems="center">
            {PALETTE_TABS_BACKGROUND.map(({ name, type }) => {
              if (type === PALETTE_TYPE_MEDIA && !showBackgroundColorSettingsPaletteTypeMedia) {
                return null
              }
              return (
                <Tab
                  key={name}
                  onClick={() => {
                    setActiveBackgroundPaletteTab(type)
                  }}
                  isActive={type === activeBackgroundPaletteTab}
                >
                  <Text
                    color={type === activeBackgroundPaletteTab ? colors.primary : colors.secondaryText}
                    fontSize="s"
                  >
                    {name}
                  </Text>
                </Tab>
              )
            })}
          </Flex>

          {activeBackgroundPaletteTab === PALETTE_TYPE_COLOR && (
            <Flex mt="s" flexDirection="column" width="100%">
              <StyledInputColor
                type="color"
                value={inputBackgroundColor}
                onChange={(event) => {
                  handleChangeParameter({
                    key: 'background',
                    value: { background_type: PALETTE_TYPE_COLOR, background_color: event.target.value },
                  })
                }}
              />
              <Grid mt="m" gridTemplateColumns="repeat(auto-fit, minmax(27px, max-content))">
                {COLORS_PALETTE.map(({ color, hasBorder }) => (
                  <StyledColorPaletteItemWrapper key={color}>
                    <span>
                      <StyledColorPaletteItem
                        boxColor={color}
                        hasBorder={hasBorder}
                        selected={color === background_color}
                        onClick={() => {
                          handleChangeParameter({
                            key: 'background',
                            value: { background_type: PALETTE_TYPE_COLOR, background_color: color },
                          })
                        }}
                      />
                    </span>
                  </StyledColorPaletteItemWrapper>
                ))}
              </Grid>
            </Flex>
          )}

          {activeBackgroundPaletteTab === PALETTE_TYPE_GRADIENT && (
            <Grid mt="s" gridTemplateColumns="repeat(auto-fit, minmax(57px, max-content))" gridGap="s">
              {GRADIENT_PALETTE.map(({ color }) => (
                <StyledGradientPaletteItemWrapper
                  key={color}
                  selected={color === background_color}
                  onClick={() => {
                    handleChangeParameter({
                      key: 'background',
                      value: { background_type: PALETTE_TYPE_GRADIENT, background_color: color },
                    })
                  }}
                  backgroundImage={color}
                />
              ))}
            </Grid>
          )}

          {activeBackgroundPaletteTab === PALETTE_TYPE_MEDIA && (
            <Flex height="400px" width="100%" flexDirection="column" mt="s" mx={`-${space.m}`} zIndex="0">
              <MediaBodyComponent
                user={user}
                confirm={() => {}}
                selectedProfiles={[]}
                selectedEntities={[entity]}
                entities={[]}
                defaultTypes={[{ value: IMAGE, label: 'Images' }]}
                isSmall
                isSelectable={false}
                isFavorable={false}
                hasItemControls={false}
                handleSpecialItemOnClick={({ media }) => {
                  const { id, media_gid, type, extension, url, thumbnail_url } = media

                  if (type === IMAGE) {
                    handleChangeParameter({
                      key: 'background',
                      value: {
                        background_type: PALETTE_TYPE_MEDIA,
                        background_media: { id, media_gid, type, extension, url, thumbnail_url },
                      },
                    })
                  } else {
                    Alert.error('Please select an image for the background.', { timeout: 5000 })
                  }
                }}
              />
            </Flex>
          )}
        </Fragment>
      )}

      {showTextColorSettings && (
        <Fragment>
          <Flex mt="m" height="30px" alignItems="center">
            {PALETTE_TABS_TEXT_COLOR.map(({ name, type }) => {
              return (
                <Tab
                  key={name}
                  onClick={() => {
                    setActiveTextPaletteTab(type)
                  }}
                  isActive={type === activeTextPaletteTab}
                >
                  <Text color={type === activeTextPaletteTab ? colors.primary : colors.secondaryText} fontSize="s">
                    {name}
                  </Text>
                </Tab>
              )
            })}
          </Flex>

          {activeTextPaletteTab === PALETTE_TYPE_COLOR && (
            <Flex mt="s" flexDirection="column" width="100%">
              <StyledInputColor
                type="color"
                value={inputTextColor}
                onChange={(event) => {
                  handleChangeParameter({
                    key: 'text_color',
                    value: { color_type: PALETTE_TYPE_COLOR, color: event.target.value },
                  })
                }}
              />
              <Grid mt="m" gridTemplateColumns="repeat(auto-fit, minmax(27px, max-content))">
                {COLORS_PALETTE.map(({ color, hasBorder }) => (
                  <StyledColorPaletteItemWrapper key={color}>
                    <span>
                      <StyledColorPaletteItem
                        boxColor={color}
                        hasBorder={hasBorder}
                        selected={color === background_color}
                        onClick={() => {
                          handleChangeParameter({
                            key: 'text_color',
                            value: { color_type: PALETTE_TYPE_COLOR, color },
                          })
                        }}
                      />
                    </span>
                  </StyledColorPaletteItemWrapper>
                ))}
              </Grid>
            </Flex>
          )}

          {activeTextPaletteTab === PALETTE_TYPE_GRADIENT && (
            <Flex mt="s" flexDirection="column" width="100%">
              <WarningMessageWrapper>
                <Text color="warning">
                  Requires latest browser. Emojis are not supported.{' '}
                  <Text
                    color="primary"
                    as="a"
                    href="https://support.vistasocial.com/hc/en-us/articles/22802704276507-Supported-Browsers-for-Gradient-Text-Color-in-Vista-Pages"
                    target="_blank"
                  >
                    Learn more
                  </Text>
                </Text>
              </WarningMessageWrapper>
              <Grid mt="m" gridTemplateColumns="repeat(auto-fit, minmax(57px, max-content))" gridGap="s">
                {GRADIENT_PALETTE.map(({ color }) => (
                  <StyledGradientPaletteItemWrapper
                    key={color}
                    selected={color === text_font_color}
                    onClick={() => {
                      handleChangeParameter({
                        key: 'text_color',
                        value: { color_type: PALETTE_TYPE_GRADIENT, color },
                      })
                    }}
                    backgroundImage={color}
                  />
                ))}
              </Grid>
            </Flex>
          )}
        </Fragment>
      )}

      {showHeaderFormatSettings && (
        <Flex mt="m" flexDirection="column" width="100%">
          <Text>Header format</Text>
          <Grid mt="s" gridTemplateColumns="repeat(2, 1fr)" gridGap="m">
            {HEADER_FORMATS.map(({ type, image }) => {
              const isSelected = header_format === type
              return (
                <StyledHeaderFormatItemWrapper
                  key={type}
                  $isSelected={isSelected}
                  onClick={() => {
                    handleChangeParameter({
                      key: 'header_format',
                      value: type,
                    })
                  }}
                >
                  <Image src={image} borderRaius={radius.l} width="100%" height="100%" />
                </StyledHeaderFormatItemWrapper>
              )
            })}
          </Grid>
        </Flex>
      )}

      {showDetailedSettings && (
        <Grid mt="m" gridTemplateColumns="repeat(2, 1fr)" gridGap="m" pr="s">
          {showHeaderMediasScaleSettings && (
            <Flex width="100%" alignItems="center" flexDirection="column">
              <Box width="100%">
                <Text>Header image scale</Text>
              </Box>
              <Slider
                defaultValue={header_media_scale}
                aria-labelledby="discrete-slider"
                step={0.1}
                min={1}
                max={3}
                value={header_media_scale}
                onChange={(event, value) => {
                  handleChangeParameter({
                    key: 'header_media_scale',
                    value,
                  })
                }}
              />
            </Flex>
          )}

          {showImageLinkImageScaleSettings && (
            <Flex width="100%" alignItems="center" flexDirection="column">
              <Box width="100%">
                <Text>Image scale</Text>
              </Box>
              <Slider
                defaultValue={image_link_image_scale}
                aria-labelledby="discrete-slider"
                step={1}
                min={1}
                max={10}
                value={image_link_image_scale}
                onChange={(event, value) => {
                  handleChangeParameter({
                    key: 'image_link_image_scale',
                    value,
                  })
                }}
              />
            </Flex>
          )}

          {showSocialLinksImagesScaleSettings && (
            <Flex width="100%" alignItems="center" flexDirection="column">
              <Box width="100%">
                <Text>Image scale</Text>
              </Box>
              <Slider
                defaultValue={social_links_image_scale}
                aria-labelledby="discrete-slider"
                step={0.1}
                min={1}
                max={3}
                value={social_links_image_scale}
                onChange={(event, value) => {
                  handleChangeParameter({
                    key: 'social_links_image_scale',
                    value,
                  })
                }}
              />
            </Flex>
          )}

          {showSocialLinksColoredSettings && (
            <Flex width="100%" alignItems="center" flexDirection="column">
              <Box width="100%">
                <Text>Color links</Text>
              </Box>
              <Slider
                defaultValue={social_links_colored}
                aria-labelledby="discrete-slider"
                step={1}
                min={0}
                max={1}
                value={social_links_colored}
                onChange={(event, value) => {
                  handleChangeParameter({
                    key: 'social_links_colored',
                    value,
                  })
                }}
              />
            </Flex>
          )}

          {showContentWidthSettings && (
            <Flex width="100%" alignItems="center" flexDirection="column">
              <Box width="100%">
                <Text>Content width</Text>
              </Box>
              <Slider
                defaultValue={content_width}
                aria-labelledby="discrete-slider"
                step={1}
                min={50}
                max={100}
                value={content_width}
                onChange={(event, value) => {
                  handleChangeParameter({
                    key: 'content_width',
                    value,
                  })
                }}
              />
            </Flex>
          )}

          {showCornerRoundnessSettings && (
            <Flex width="100%" alignItems="center" flexDirection="column">
              <Box width="100%">
                <Text>Corner roundness</Text>
              </Box>
              <Slider
                defaultValue={corner_radius}
                aria-labelledby="discrete-slider"
                step={0.1}
                min={0}
                max={2}
                value={corner_radius}
                onChange={(event, value) => {
                  handleChangeParameter({
                    key: 'corner_radius',
                    value,
                  })
                }}
              />
            </Flex>
          )}

          {showShadowSettings && (
            <Flex width="100%" alignItems="center" flexDirection="column">
              <Box width="100%">
                <Text>Shadow</Text>
              </Box>
              <Slider
                defaultValue={shadow}
                aria-labelledby="discrete-slider"
                step={0.1}
                min={0}
                max={3}
                value={shadow}
                onChange={(event, value) => {
                  handleChangeParameter({
                    key: 'shadow',
                    value,
                  })
                }}
              />
            </Flex>
          )}

          {showTransparencySettings && (
            <Flex width="100%" alignItems="center" flexDirection="column">
              <Box width="100%">
                <Text>Transparency</Text>
              </Box>
              <Slider
                defaultValue={transparency}
                aria-labelledby="discrete-slider"
                step={0.1}
                min={0}
                max={1}
                value={transparency}
                onChange={(event, value) => {
                  handleChangeParameter({
                    key: 'transparency',
                    value,
                  })
                }}
              />
            </Flex>
          )}

          {showTextScaleSettings && (
            <Flex width="100%" alignItems="center" flexDirection="column">
              <Box width="100%">
                <Text>Font size</Text>
              </Box>
              <Slider
                defaultValue={text_scale}
                aria-labelledby="discrete-slider"
                step={0.1}
                min={0.5}
                max={2}
                value={text_scale}
                onChange={(event, value) => {
                  handleChangeParameter({
                    key: 'text_scale',
                    value,
                  })
                }}
              />
            </Flex>
          )}

          {showTextFontWeightSettings && (
            <Flex width="100%" alignItems="center" flexDirection="column">
              <Box width="100%">
                <Text>Font weight</Text>
              </Box>
              <Slider
                defaultValue={text_font_weight}
                aria-labelledby="discrete-slider"
                step={100}
                min={300}
                max={700}
                value={text_font_weight}
                onChange={(event, value) => {
                  handleChangeParameter({
                    key: 'text_font_weight',
                    value,
                  })
                }}
              />
            </Flex>
          )}

          {showTextFontStyleSettings && (
            <Flex width="100%" alignItems="center" flexDirection="column">
              <Box width="100%">
                <Text>Font style</Text>
              </Box>
              <Slider
                defaultValue={text_font_style}
                aria-labelledby="discrete-slider"
                step={1}
                min={0}
                max={1}
                value={text_font_style}
                onChange={(event, value) => {
                  handleChangeParameter({
                    key: 'text_font_style',
                    value,
                  })
                }}
              />
            </Flex>
          )}
        </Grid>
      )}
    </Fragment>
  )
}

ThemeComponent.defaultProps = {
  user: {},
  data: {},
  showBackgroundColorSettings: true,
  showBackgroundColorSettingsPaletteTypeMedia: true,
  showTextColorSettings: true,
  showDetailedSettings: true,
  showContentWidthSettings: true,
  showCornerRoundnessSettings: true,
  showShadowSettings: true,
  showTransparencySettings: true,
  showTextScaleSettings: true,
  showTextFontWeightSettings: true,
  showTextFontStyleSettings: true,
  showHeaderFormatSettings: true,
  showHeaderMediasScaleSettings: true,
  showSocialLinksColoredSettings: true,
  showSocialLinksImagesScaleSettings: true,
  showImageLinkImageScaleSettings: true,
}

ThemeComponent.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object,
  handleChangeParameter: PropTypes.func.isRequired,
  showBackgroundColorSettings: PropTypes.bool,
  showBackgroundColorSettingsPaletteTypeMedia: PropTypes.bool,
  showTextColorSettings: PropTypes.bool,
  showDetailedSettings: PropTypes.bool,
  showContentWidthSettings: PropTypes.bool,
  showCornerRoundnessSettings: PropTypes.bool,
  showShadowSettings: PropTypes.bool,
  showTransparencySettings: PropTypes.bool,
  showTextScaleSettings: PropTypes.bool,
  showTextFontWeightSettings: PropTypes.bool,
  showTextFontStyleSettings: PropTypes.bool,
  showHeaderFormatSettings: PropTypes.bool,
  showHeaderMediasScaleSettings: PropTypes.bool,
  showSocialLinksColoredSettings: PropTypes.bool,
  showSocialLinksImagesScaleSettings: PropTypes.bool,
  showImageLinkImageScaleSettings: PropTypes.bool,
}

ThemeComponent.displayName = 'ThemeComponent'

export default ThemeComponent
