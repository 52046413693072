import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Alert from 'react-s-alert'
import { transparentize } from 'polished'
import { COLOR_CONSTANTS, radius, space } from 'theme'
import { ERROR_MESSAGE } from 'consts'
import errorHelper from 'utils/errorHelper'
import request from 'utils/request'
import { Box, Flex } from 'components/atoms/Layout'
import Input from 'components/atoms/Input'
import Image from 'components/atoms/Image'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import { ROUTE_VISTA_PAGE } from 'routes/VistaPage/consts'

const StyledQrCodeWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  border-radius: ${radius.l};
  background: ${transparentize(0.5, COLOR_CONSTANTS.WHITE)};
  padding: ${space.m};
`

const StyledQrCodeImage = styled(Image)`
  width: 100%;
  max-width: 350px;
  border-radius: ${radius.l};
  margin: 0 auto;
`

const { LINK_EXTERNAL, QR_CODE } = {
  LINK_EXTERNAL: 'link',
  QR_CODE: 'qr_code',
}

const QrCodeComponent = ({ data, vistaPage, isEditable, handleChangeBlockComponent }) => {
  const [isGeneratingData, setIsGeneratingData] = useState(false)

  const { _id } = vistaPage

  const { page_id, _id: block_id, data: { link: link_external = '', qr_code = '' } = {} } = data

  const handleClickGenerateQrCode = async () => {
    if (!link_external) {
      Alert.error(`Please type a link before generating a QR code.`, { timeout: 5000 })
    } else {
      try {
        const response = await request({
          method: 'POST',
          path: `${ROUTE_VISTA_PAGE}/${_id}/qr`,
          body: { link: link_external },
        })

        const { error, data } = response || {}

        if (error || !response) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else {
          handleChangeBlockComponent({ page_id, block_id, key: QR_CODE, value: data })
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: QrCodeComponent.displayName,
          functionName: 'handleClickGenerateQrCode',
        })
      } finally {
        setIsGeneratingData(false)
      }
    }
  }

  return (
    <Fragment>
      {isEditable ? (
        <Flex flexDirection="column" width="100%" height="100%">
          <Box>
            <Input
              placeholder="Button link"
              type="text"
              defaultValue={link_external}
              onChange={(event) => {
                handleChangeBlockComponent({ page_id, block_id, key: LINK_EXTERNAL, value: event.target.value })
              }}
              width="100%"
            />
          </Box>
          <Flex mt="m" width="100%" justifyContent="center">
            <ButtonWithLoading isSmall onClick={handleClickGenerateQrCode} isLoading={isGeneratingData}>
              {isGeneratingData ? 'Generating' : 'Generate'}
            </ButtonWithLoading>
          </Flex>
        </Flex>
      ) : (
        <StyledQrCodeWrapper>{qr_code && <StyledQrCodeImage src={qr_code} download />}</StyledQrCodeWrapper>
      )}
    </Fragment>
  )
}

QrCodeComponent.defaultProps = {
  isPublic: false,
  isEditable: false,
  handleChangeBlockComponent: () => {},
}

QrCodeComponent.propTypes = {
  data: PropTypes.object.isRequired,
  vistaPage: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  handleChangeBlockComponent: PropTypes.func,
  isPublic: PropTypes.bool,
}

QrCodeComponent.displayName = 'QrCodeComponent'

export default QrCodeComponent
