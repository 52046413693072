import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space as styledSpace } from 'styled-system'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Slider } from '@material-ui/core'
import { COLOR_CONSTANTS, radius, space } from 'theme'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Input from 'components/atoms/Input'
import { DEFAULT_PUBLICATIONS_MAXIMUM } from 'routes/VistaPage/consts'
import PreviewComponent from 'routes/Calendar/components/PreviewModal/PreviewComponent'

const StyledHeaderTextWrapper = styled(Flex)`
  width: 100%;
  align-items: center;
  padding: ${space.m};
  border-bottom: 1px solid ${COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL};
`

const StyledPreviewWrapper = styled(Box)`
  position: relative;
  width: 100%;
  padding: 0;
  border-radius: ${radius.s};
  ${styledSpace};
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
`

const { HEADER_TEXT, MAX_PUBLICATIONS } = {
  HEADER_TEXT: 'header_text',
  MAX_PUBLICATIONS: 'max_publications',
}

const FormValidationSchema = Yup.object().shape({
  [HEADER_TEXT]: Yup.string().max(200, `Header text is too long - should be 200 chars maximum.`),
})

const PublicationsComponent = ({
  data,
  isEditable,
  handleChangeBlockComponent,
  handleTrackAnalyticsMetrics,
  isPublic,
}) => {
  const {
    page_id,
    _id: block_id,
    data: { publications = [], header_text = '', max_publications = DEFAULT_PUBLICATIONS_MAXIMUM } = {},
  } = data

  return (
    <Fragment>
      {isEditable ? (
        <Flex flexDirection="column" width="100%" height="100%">
          <Formik
            initialValues={{
              [HEADER_TEXT]: header_text,
              [MAX_PUBLICATIONS]: max_publications,
            }}
            validationSchema={FormValidationSchema}
            onSubmit={() => {}}
            autocomplete="off"
            validateOnBlur
          >
            {({ values, errors, setFieldValue }) => (
              <Box width="100%">
                <Box>
                  <Input
                    placeholder="Header text"
                    type="text"
                    value={values[HEADER_TEXT]}
                    onChange={(event) => {
                      handleChangeBlockComponent({ page_id, block_id, key: HEADER_TEXT, value: event.target.value })
                      setFieldValue(HEADER_TEXT, event.target.value)
                    }}
                    error={values[HEADER_TEXT] && errors[HEADER_TEXT]}
                    width="100%"
                  />
                </Box>
                <Flex mt="m" width="50%" alignItems="center" flexDirection="column">
                  <Box width="100%">
                    <Text>Amount of publications</Text>
                  </Box>
                  <Slider
                    defaultValue={max_publications}
                    aria-labelledby="discrete-slider"
                    step={1}
                    min={1}
                    max={10}
                    value={max_publications}
                    onChange={(event, value) => {
                      handleChangeBlockComponent({
                        page_id,
                        block_id,
                        key: MAX_PUBLICATIONS,
                        value,
                      })
                    }}
                    valueLabelDisplay="auto"
                  />
                </Flex>
              </Box>
            )}
          </Formik>
        </Flex>
      ) : (
        <Fragment>
          {((isPublic && publications.length > 0) || !isPublic) && (
            <Fragment>
              <Flex
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                bg={COLOR_CONSTANTS.WHITE}
                borderRadius={radius.l}
              >
                <StyledHeaderTextWrapper>
                  <Text fontWeight="bold" fontSize="l">
                    {header_text || 'Updates'}
                  </Text>
                </StyledHeaderTextWrapper>

                {publications.length > 0 ? (
                  <Fragment>
                    {publications.map((post) => {
                      return (
                        <Box key={post.id} width="100%" mx="auto" px="m">
                          <Box height={space.m} width="100%" />
                          <StyledPreviewWrapper>
                            <PreviewComponent
                              postForEdit={post}
                              hasComponentControls={false}
                              noVideoAttachment
                              showHeader={false}
                              showAnonymous
                              handleTrackAnalyticsMetrics={handleTrackAnalyticsMetrics}
                            />
                          </StyledPreviewWrapper>
                          <Box height={space.m} width="100%" />
                        </Box>
                      )
                    })}
                  </Fragment>
                ) : (
                  <Flex alignItems="center" justifyContent="center" width="100%" p="m">
                    <Text textAlign="center" fontWeight="bold">
                      This section will display posts published to this page.
                    </Text>
                  </Flex>
                )}
              </Flex>
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

PublicationsComponent.defaultProps = {
  isEditable: false,
  handleChangeBlockComponent: () => {},
  handleTrackAnalyticsMetrics: () => {},
  isPublic: true,
}

PublicationsComponent.propTypes = {
  data: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  handleChangeBlockComponent: PropTypes.func,
  handleTrackAnalyticsMetrics: PropTypes.func,
  isPublic: PropTypes.bool,
}

PublicationsComponent.displayName = 'ButtonLinkComponent'

export default PublicationsComponent
